@import "~react-image-gallery/styles/css/image-gallery.css";
/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.themeButton {
  background-color: #4c7ee8 !important;
  width: 70px;
  height: 35px;
  margin-left: 10px;
  font-size: small;
  color: white;
  border: none;
  border-radius: 5px;
}
.themeButtonNo {
  background-color: #ff6b6b !important;
  width: 70px;
  height: 35px;
  margin-left: 10px;
  font-size: small;
  color: white;
  border: none;
  border-radius: 5px;
}
.themeButtonYes {
  background-color: rgb(83, 219, 90);
  width: 70px;
  height: 35px;
  margin-left: 10px;
  font-size: small;
  color: white;
  border: none;
  border-radius: 5px;
}
.activeButton {
  color: white !important;
  background-color: #4c7ee8 !important;
}
.my-tabs {
  width: 100%;
}
@media screen and (max-width: 680px) {
  .my-tabs {
    height: 60px;
    width: 100%;
  }
  .my-tabs button {
    font-size: 15px;
  }
}
.thinScroll {
  overflow-y: scroll;
}
.thinScroll::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.thinScroll::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.thinScroll::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

.helperText {
  font-size: 12px;
  color: rgb(255, 84, 84);
}


.__indicator-separator {
  display: none;
}
.selectCustom {
  z-index: 99 !important;
  color: unset !important;
}
.__menu {
  width: max-content !important;
  z-index: 99 !important;
}
/* .selectCustom .__control  {
  height: 45px;
  border: 1px solid #989898 !important;
  background: transparent;
} */
.selectCustom .__dropdown-indicator svg{
  display: none;
}

.selectCustom .__value-container {
  padding-inline: 0px !important;
}
.__group-heading {
  font-size: 16px !important;
}
.__placeholder {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .owl-nav {
    display: none !important;
  }
}



.slides::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  height: 0;
}
.__menu-list::-webkit-scrollbar {
  width: 5px;  /* Remove scrollbar space */
  height: 5px;
}
/* Track */
.__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.__menu-list::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}



.my-radio {
  display: flex;
  font-size: 17px;
  align-items: center;
}
.my-radio-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  border: 1px solid #4C7EE8;
}
.my-radio-icon-inner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.my-radio-active {
  background-color: #4C7EE8;
}

.car-detail-modal {
  max-width: unset;
  width: 50%;
}
@media screen and (max-width: 1350px) {
  .car-detail-modal {
    width: 70%;
  }
}
@media screen and (max-width: 992px) {
  .car-detail-modal {
    width: 80%;
  }
}
@media screen and (max-width: 600px) {
  .car-detail-modal {
    width: 100%;
  }
}

.modalNoThumb::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  height: 0px;
}

.my-image-gallery .image-gallery-swipe {
 width: 100vw;
}

.sidebar-item:hover {
  background-color: #ebebeb;
}

.tooltip-style:hover {
  background-color: #ebebeb;
}

.btn-link {
  transition: ease-in-out .2s;
  height: 26px;
}